import { HTTPURL } from "../../constants/Matcher";




export async function getStudyMaterial(id) {
    try {
        const response = await fetch(HTTPURL + `api/student/get-study-material-year/?id=${id}`
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function getlectureData() {
    try {
        const response = await fetch(HTTPURL +`api/student/student-get-class-lecture-data`
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function getlectureData_List(id,c_id) {
    
    try {
        const response = await fetch(HTTPURL +`api/student/student-get-class-lecture-lesson-data/?id=${id}&c_id=${c_id}`
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function enroll_student_payment(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-update-to-enrolled', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}






