import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getlectureData,enroll_student_payment } from "../../../apis/My-e-Test/Classes";
import { get_branch } from '../../../apis/Common';
import Pagination from '../../Pagination';
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
const Classes = () => {
    const [open, setOpen] = useState(false);
    let itemsPerPage = 5;
    const [lectureData, setLectureData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [branch, setBranch] = useState([]);
    const [branchId, setBranchId] = useState('');
    const [enrollStatus, setEnrollStatus] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const grade=localStorage.getItem('grade');
    const userID=localStorage.getItem('studentId')
    const totalPages = Math.ceil(lectureData?.length / itemsPerPage);
    useEffect(() => {
        async function get_data() {
            const lecturlist = await getlectureData(currentPage);
            console.log("checklist------",lecturlist);
            
            const branch = await get_branch();
            setLectureData(lecturlist?.data);
            setBranch(branch?.data);
        }
        get_data();
    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const submitData = async (e) => {
        e.preventDefault();
        const formData = {
            'grade_id': grade,
            'user_id': userID,
            'total_price': 10,
            'branch_id': branchId,
            'subject_id':0,
            'payment_method': paymentMethod
        }
        console.log('fom',formData);
        if (enrollStatus === '1') {
            const res = await enroll_student_payment(formData);
            if (res?.status) {
                setOpen(false);
                const studentType = localStorage.setItem('studentType',1) 
            }
        } else {
            // toast.error('Enrolled status is required');
            console.log('error');
        }

    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = lectureData?.slice(startIndex, endIndex);
    const studentType = localStorage.getItem('studentType')
    return (
        <>
            <div className="col-12">
                <div className="row">
                    <div className="table_head">
                        <h2 className="">Lectures</h2>
                    </div>
                    <div className="table-responsive tbl_bg">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Lectures</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentData?.length > 0 ?
                                    currentData && currentData?.map((elem) => {
                                        const dateTimeComponents = elem?.date?.split(' ');
                                        const dateComponents = dateTimeComponents[0]?.split('-');
                                        const timeComponents = dateTimeComponents[1]?.split(':');
                                        const year = parseInt(dateComponents[0]);
                                        const month = parseInt(dateComponents[1]) - 1; // Months are zero-indexed
                                        const day = parseInt(dateComponents[2]);
                                        const hours = parseInt(timeComponents[0]);
                                        const minutes = parseInt(timeComponents[1]);
                                        const seconds = parseInt(timeComponents[2]);
                                        const formattedDate = new Date(year, month, day, hours, minutes, seconds).toISOString().substring(0, 10);
                                        return (
                                            <tr>
                                                <td scope="row">{elem?.subject}</td>
                                                <td>{`${formattedDate}`}</td>
                                                <td>
                                                    {studentType === '1'
                                                        ? <Link className='edit-btn' to={`/Classes/lecture-list/${elem?.id}/${elem?.c_id}`}>View</Link>
                                                        : <button className='edit-btn' onClick={() => setOpen(true)}>Enroll Now</button>}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <p>No Data</p>
                                }
                            </tbody>
                        </table>
                        <div className="pagin">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                    <div className="tbl_content">
                        <ol type="1">
                            <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li>
                            <li>You can take the test ONLY ONCE during the available period.</li>
                        </ol>
                    </div>
                </div>
            </div>
            <Modal show={open} onHide={() => setOpen(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton       >
                    <Modal.Title>Shift to Enrolled</Modal.Title>
                </Modal.Header>
                <Modal.Body className='cash-popup'>
                    <form>
                        <div className='row'>
                        <div className="col-lg-12 col-12">
                            <div className="mb-3 info_field">
                                <label for="exampleFormControlInput1" className="form-label">Branch</label>
                                <select className="form-select" name="branch" onChange={(e) => setBranchId(e.target.value)}>
                                    <option value=''>Choose Branch</option>
                                    {branch && branch.map((elem) => {
                                        return (
                                            <option value={elem.id}>{elem.branch_name}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="form-group">
                                <label htmlFor='enrolled'>Enrolled Status: </label>
                                <div className='highlight_inner'>
                                    <div className=" checkbox">
                                        <label>
                                            <input type="checkbox" name='student_type' id="enrolled" value="1"
                                                onChange={(e) => setEnrollStatus(e.target.value)}
                                                required /> Yes </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-12">
                        <div className="form-group">
                            <label htmlFor='enrolled'>Payment Method </label>
                            <div className='highlight_inner'>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="cash"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Cash </label>
                                </div>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="online"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Online </label>
                                </div>
                                <div className=" checkbox">
                                    <label>
                                        <input type="radio" name='payment_method' id="enrolled" value="payLater"
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        /> Pay Later </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12">
                        <button type="submit" className="btn btn-primary cashbutton" onClick={(e) => submitData(e)}>Save</button>
                    </div>
                        </div>
                      
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Classes


